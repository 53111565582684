import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "react-bootstrap"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <Container>
          <Row>
            <Col sm={12}>
              <h2>Here be stuff about this site...</h2>
              <p>
                Cupcake ipsum dolor sit amet. Tootsie roll bear claw muffin icing bonbon dragée biscuit halvah croissant. Halvah halvah icing gingerbread. Tart marzipan danish topping lemon drops pie candy bear claw. Jujubes topping gummies sweet roll pastry. Jujubes topping halvah.
              </p>
              <h3>Introduction</h3>
              <p>
                Chocolate bar sweet roll carrot cake muffin icing danish gingerbread. Danish bonbon biscuit cookie jelly beans croissant. Ice cream jelly-o topping bear claw tart chupa chups. Chocolate bonbon jelly beans powder lemon drops. Halvah icing jelly tootsie roll oat cake chocolate cake sesame snaps. Tootsie roll oat cake tart cookie chocolate bar sweet roll. Pudding halvah danish sugar plum ice cream chocolate cake.
              </p>
              <h3>Explanation</h3>
              <p>
                Danish pastry chocolate cake tootsie roll chocolate bar wafer. Gingerbread fruitcake tootsie roll dessert croissant topping. Oat cake tootsie roll bear claw cookie tootsie roll muffin sugar plum bear claw topping. Cotton candy cotton candy sugar plum cookie. Chocolate bar muffin lollipop cotton candy halvah chocolate cake. Croissant icing halvah danish jelly beans lemon drops pudding cake jelly-o. Candy sweet roll macaroon cheesecake cheesecake toffee. Topping biscuit donut cake cheesecake cookie macaroon lemon drops pie. Sweet roll cake jujubes donut marshmallow oat cake. Caramels cotton candy sesame snaps sugar plum dessert caramels jujubes.
              </p>
              <h3>How to carry on</h3>
              <p>
                Cupcake toffee jelly-o icing carrot cake fruitcake marzipan jelly. Gingerbread caramels danish icing halvah tart. Caramels donut gingerbread fruitcake sesame snaps pastry powder. Topping sugar plum cookie cookie lollipop muffin gingerbread icing powder. Croissant sesame snaps soufflé tiramisu chupa chups chupa chups jujubes pudding. Marzipan dessert jelly beans.
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
